import {
  EntityListOfEnterpriseProductCountByUsersItemDto,
  ServiceVariantProduct,
  getEnterprisesEnterpriseProductCountByUserProduct,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getEnterpriseProductCountByUsersAsync = createAsyncAction(
  "@ENTERPRISE/GET_ENTERPRICE_PRODUCT_COUNT_BY_USERS_REQUEST",
  "@ENTERPRISE/GET_ENTERPRICE_PRODUCT_COUNT_BY_USERS_RESPONSE",
  "@ENTERPRISE/GET_ENTERPRICE_PRODUCT_COUNT_BY_USERS_FAILURE",
)<
  { product: ServiceVariantProduct; offset: number; limit: number },
  EntityListOfEnterpriseProductCountByUsersItemDto,
  Error
>();

function* getEnterpriseProductCountByUsers(
  action: ReturnType<typeof getEnterpriseProductCountByUsersAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getEnterprisesEnterpriseProductCountByUserProduct,
      action.payload.product,
      action.payload.offset,
      action.payload.limit,
    );

    if (status === 200) {
      yield put(getEnterpriseProductCountByUsersAsync.success(data));
      return;
    }

    yield put(
      getEnterpriseProductCountByUsersAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(getEnterpriseProductCountByUsersAsync.failure(err as Error));
  }
}
export function* getEnterpriseProductCountByUsersSaga() {
  yield takeLatest(
    getType(getEnterpriseProductCountByUsersAsync.request),
    getEnterpriseProductCountByUsers,
  );
}

export const getEnterpriseProductCountByUsersSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EntityListOfEnterpriseProductCountByUsersItemDto>(
      false,
    ),
  name: "getEnterpriseProductCountByUsers",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EntityListOfEnterpriseProductCountByUsersItemDto>(
        false,
      ),
  },
  extraReducers: builder => {
    handleRequestCase(getEnterpriseProductCountByUsersAsync, builder);
    handleSuccessCase(getEnterpriseProductCountByUsersAsync, builder);
    handleFailureCase(getEnterpriseProductCountByUsersAsync, builder);
  },
});

export const { resetSlice: getEnterpriseProductCountByUsersReset } =
  getEnterpriseProductCountByUsersSlice.actions;
