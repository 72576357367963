import { combineReducers } from "@reduxjs/toolkit";
import {
  searchAdvisorUsersInEnterpriseSaga,
  searchAdvisorUsersInEnterpriseSlice,
} from "State/Users/SearchAdvisorUsersInEnterpriseSlice";
import { all } from "typed-redux-saga";

export const userReducer = combineReducers({
  searchAdvisorUsersInEnterprise: searchAdvisorUsersInEnterpriseSlice.reducer,
});

export function* watchUserSagas() {
  yield all([searchAdvisorUsersInEnterpriseSaga()]);
}
