import { yupResolver } from "@hookform/resolvers/yup";
import { Typography, Divider } from "@mui/material";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { BlDefaultButton } from "Components/Shared/Buttons/BlDefaultButton";
import { StyledDialog } from "Components/Shared/Dialogs/DialogStyles";
import { CrossIcon } from "Components/Shared/Icons";
import {
  BlAutocomplete,
  LookupItem,
} from "Components/Shared/Inputs/Autocomplete/BlAutocomplete";
import { BlDatePicker } from "Components/Shared/Inputs/DatePickers/BlDatePicker";
import { BlCheckboxInput } from "Components/Shared/Inputs/Form/BlCheckboxInput";
import { BlFormInput } from "Components/Shared/Inputs/Form/BlFormInput";
import { StyledFlex } from "Components/Shared/StyledComponents";
import useHandleServerError from "Hooks/Form/useHandleServerError";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import {
  createCommissionIndividualSettingAsync,
  resetError,
} from "State/Enterprises/Commission/CreateCommissionIndividualSettingsSlice";
import { useAppSelector } from "State/Store";
import {
  resetSearchAdvisorUsersInEnterpriseSlice,
  searchAdvisorUsersInEnterpriseAsync,
} from "State/Users/SearchAdvisorUsersInEnterpriseSlice";
import { Resources, useResource } from "Translations/Resources";
import { toLocalTimeZoneString } from "Utils/DateUtils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { object, date, number, ObjectSchema, boolean } from "yup";

const StyledInputsWrapper = styled(StyledFlex)`
  & > div {
    flex: 1;
  }

  label {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .Mui-disabled.MuiInputBase-formControl {
    background-color: transparent;

    .MuiInputBase-input {
      color: ${({ theme }) => theme.palette.text.primary};
      -webkit-text-fill-color: ${({ theme }) => theme.palette.text.primary};
      opacity: 0.5;
    }
  }
`;

type Props = {
  onClosed: () => void;
};

type FormModel = {
  dateFrom: Date;
  enterpriseCommissionRate: number;
  advisorCommissionRate: number;
  secondAdvisorCommissionRate: number;
  userID?: number | null;
  secondUserID?: number | null;
  isSecondAdvisor: boolean;
};

export const CommissionIndividualDialogForm: React.FunctionComponent<
  Props
> = props => {
  const { onClosed } = props;
  const { t } = useResource();

  const validationSchema: ObjectSchema<FormModel> = object({
    dateFrom: date().required(),
    enterpriseCommissionRate: number()
      .required()
      .test({
        test: (v, c: { parent: FormModel }) => {
          return (
            v +
              c.parent.secondAdvisorCommissionRate +
              c.parent.advisorCommissionRate ===
            100
          );
        },
        exclusive: false,
        message: t(Resources.Settings.CommissionIndividual.TotalCommission),
      }),
    advisorCommissionRate: number()
      .required()
      .test({
        test: (v, c: { parent: FormModel }) => {
          return (
            v +
              c.parent.secondAdvisorCommissionRate +
              c.parent.enterpriseCommissionRate ===
            100
          );
        },
        exclusive: false,
        message: "",
      }),
    secondAdvisorCommissionRate: number()
      .optional()
      .default(0)
      .test({
        test: (v, c: { parent: FormModel }) => {
          return (
            v +
              c.parent.advisorCommissionRate +
              c.parent.enterpriseCommissionRate ===
            100
          );
        },
        exclusive: false,
        message: "",
      }),
    secondUserID: number()
      .nullable()
      .when("isSecondAdvisor", {
        is: true,
        then: schema => schema.required("Vyberte druhého poradce"),
        otherwise: schema => schema.nullable(),
      }),
    isSecondAdvisor: boolean().required(),
    userID: number().required("Vyberte poradce"),
  }).defined();

  const form = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      dateFrom: new Date(),
      enterpriseCommissionRate: 0,
      advisorCommissionRate: 0,
      secondAdvisorCommissionRate: 0,
      isSecondAdvisor: false,
      secondUserID: null,
      userID: null,
    },
  });

  const {
    control,
    watch,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = form;

  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }

    handleSubmit((formModel: FormModel) => {
      dispatch(
        createCommissionIndividualSettingAsync.request({
          userID: formModel.userID!,
          advisorCommissionRate: formModel.advisorCommissionRate / 100,
          enterpriseCommissionRate: formModel.enterpriseCommissionRate / 100,
          secondAdvisorCommissionRate:
            formModel.secondAdvisorCommissionRate / 100,
          secondUserID: formModel.secondUserID,
          dateFrom: toLocalTimeZoneString(formModel.dateFrom),
        }),
      );
    })();
  };

  const { error } = useAppSelector(
    e => e.enterprise.createCommissionIndividualSetting,
  );

  const { data: searchAdvisorUsers, isLoading: isSearchAdvisorUsersLoading } =
    useAppSelector(e => e.user.searchAdvisorUsersInEnterprise);

  const SafeHTML: React.FC<{ html: string }> = ({ html }) => (
    <span dangerouslySetInnerHTML={{ __html: html }} />
  );

  const [userName, setUserName] = useState<string>(
    t(Resources.CommissionIndividual.Create.UserRate),
  );
  const [secondUserName, setSecondUserName] = useState<string>(
    t(Resources.CommissionIndividual.Create.UserSecondRate),
  );

  const isSecondAdvisor = watch("isSecondAdvisor");
  const watchedFirstUserID = watch("userID");
  const watchedSecondUserID = watch("secondUserID");

  const enterpriseCommissionRate = watch("enterpriseCommissionRate");
  const advisorCommissionRate = watch("advisorCommissionRate");
  const secondAdvisorCommissionRate = watch("secondAdvisorCommissionRate");

  const rateSum =
    enterpriseCommissionRate +
    advisorCommissionRate +
    secondAdvisorCommissionRate;

  const userAutocompleteOptions = useMemo(
    () => (isSecondPerson: boolean) =>
      searchAdvisorUsers?.items
        .filter(x =>
          isSecondPerson
            ? x.userID !== watchedFirstUserID
            : x.userID !== watchedSecondUserID,
        )
        .map(x => {
          const externalIDPart = x.externalID.includes("-")
            ? x.externalID.split("-")[0]
            : x.externalID;

          const companyname = !!x.companyFormatName
            ? `, ${x.companyFormatName}`
            : "";
          return {
            id: x.userID,
            name: `${x.partyFormatName} [${externalIDPart}]${companyname}`,
          } satisfies LookupItem;
        }) ?? [],
    [searchAdvisorUsers, watchedFirstUserID, watchedSecondUserID],
  );

  const search = useCallback(
    (query: string) => {
      if (query?.length >= 3) {
        dispatch(
          searchAdvisorUsersInEnterpriseAsync.request({
            query,
          }),
        );
      }
    },
    [dispatch],
  );

  const handleBlAutocompleteOnBlur = (e: any) => {
    dispatch(resetSearchAdvisorUsersInEnterpriseSlice());
  };

  useEffect(() => {
    if (!isSecondAdvisor) {
      setValue("secondUserID", null);
      setValue("secondAdvisorCommissionRate", 0);
      setSecondUserName(
        t(Resources.CommissionIndividual.Create.UserSecondRate),
      );
    }
  }, [isSecondAdvisor, setValue, t]);

  const onExistingUserChange = useCallback(
    (val: LookupItem | null, isSecondUser: boolean) => {
      const user =
        val &&
        searchAdvisorUsers?.items?.find(
          x => x.userID !== 0 && x.userID === val.id,
        );
      setValue(isSecondUser ? "secondUserID" : "userID", user?.userID ?? null);
      isSecondUser
        ? setSecondUserName(
            user?.partyFormatName ??
              t(Resources.CommissionIndividual.Create.UserSecondRate),
          )
        : setUserName(
            user?.partyFormatName ??
              t(Resources.CommissionIndividual.Create.UserRate),
          );
    },
    [searchAdvisorUsers?.items, setValue, t],
  );

  useHandleServerError({
    form,
    formKey: "dateFrom",
    error,
    resource: Resources.CommissionIndividual.Create.Validation,
  });
  return (
    <>
      <StyledDialog open={true} onClose={onClosed}>
        <StyledFlex $alignItems="center" $justifyContent="space-between">
          <Typography variant="h2">
            {t(Resources.CommissionIndividual.Create.Title)}
          </Typography>

          <BlDefaultButton
            title={t(Resources.Common.Close)}
            onClick={() => onClosed()}
          >
            <CrossIcon />
          </BlDefaultButton>
        </StyledFlex>

        <Divider sx={{ marginTop: 1, marginBottom: 2 }} />

        <div>
          <StyledFlex $gap={0.5} $alignItems="center">
            <Typography variant="subtitle2" fontStyle={"normal"}>
              {t(Resources.CommissionIndividual.Create.Description)}
            </Typography>
          </StyledFlex>

          <StyledInputsWrapper $gap={1} $marginTop={2.5} $marginBottom={0.5}>
            <BlDatePicker
              label={t(Resources.CommissionIndividual.Create.DateFrom)}
              name="dateFrom"
              value={watch("dateFrom")}
              onChange={v => {
                setValue("dateFrom", v ?? ("" as unknown as Date));
                dispatch(resetError());
              }}
              hasError={!!errors.dateFrom}
              errorMessage={errors.dateFrom?.message}
              disablePast
              readOnlyField
            />
          </StyledInputsWrapper>

          <BlAutocomplete
            label={t(Resources.CommissionIndividual.Create.User)}
            options={userAutocompleteOptions(false)}
            onOptionSelected={option => onExistingUserChange(option, false)}
            debounceTime={100}
            onSearch={search}
            placeholder={"Jméno poradce"}
            isLoading={isSearchAdvisorUsersLoading}
            onBlur={handleBlAutocompleteOnBlur}
            fieldError={!!errors.userID ? errors.userID : undefined}
          />

          <StyledInputsWrapper $gap={1} $marginBottom={2} $marginTop={1}>
            <BlCheckboxInput
              control={control}
              name={"isSecondAdvisor"}
              errors={errors}
              label={t(
                Resources.CommissionIndividual.Create.IsSharingCommission,
              )}
            />
          </StyledInputsWrapper>
          {isSecondAdvisor && (
            <BlAutocomplete
              label={t(Resources.CommissionIndividual.Create.UserSecond)}
              options={userAutocompleteOptions(true)}
              onOptionSelected={option => onExistingUserChange(option, true)}
              debounceTime={100}
              onSearch={search}
              placeholder="Jméno poradce"
              isLoading={isSearchAdvisorUsersLoading}
              onBlur={handleBlAutocompleteOnBlur}
              fieldError={
                !!errors.secondUserID ? errors.secondUserID : undefined
              }
            />
          )}
          <StyledInputsWrapper $gap={1} $marginBottom={0.5} $marginTop={1}>
            <BlFormInput
              control={control}
              errors={errors}
              name={"enterpriseCommissionRate"}
              label={t(Resources.CommissionIndividual.Create.EnterpriseRate)}
              mask={Number}
              isNumber
              max={100}
              min={0}
              unit="%"
            />

            <BlFormInput
              control={control}
              errors={errors}
              name={"advisorCommissionRate"}
              label={userName}
              mask={Number}
              isNumber
              max={100}
              min={0}
              unit="%"
            ></BlFormInput>
            {isSecondAdvisor && (
              <BlFormInput
                control={control}
                errors={errors}
                name={"secondAdvisorCommissionRate"}
                label={secondUserName}
                mask={Number}
                isNumber
                max={100}
                min={0}
                unit="%"
              ></BlFormInput>
            )}
          </StyledInputsWrapper>

          {rateSum === 100 && (
            <StyledInputsWrapper $gap={1} $marginBottom={2} $marginTop={1}>
              <Typography variant="subtitle2">
                <SafeHTML
                  html={t(
                    isSecondAdvisor
                      ? Resources.CommissionIndividual.Create
                          .ExampleIncludeSecondAdvisor
                      : Resources.CommissionIndividual.Create.Example,
                    {
                      hspPrice: (1000 * enterpriseCommissionRate) / 100,
                      hspPerc: enterpriseCommissionRate,
                      advisorPrice: (1000 * advisorCommissionRate) / 100,
                      advisorPerc: advisorCommissionRate,
                      secondAdvisorPrice:
                        (1000 * secondAdvisorCommissionRate) / 100,
                      secondAdvisorPerc: secondAdvisorCommissionRate,
                    },
                  )}
                />
              </Typography>
            </StyledInputsWrapper>
          )}

          <StyledFlex $alignItems="center" $gap={1}>
            <BlButton color="primary" fullWidth onClick={() => onSubmit()}>
              {t(Resources.Common.Save_FirstUppercase)}
            </BlButton>

            <BlButton color="secondary" fullWidth onClick={() => onClosed()}>
              {t(Resources.Common.Cancel)}
            </BlButton>
          </StyledFlex>
        </div>
      </StyledDialog>
    </>
  );
};
