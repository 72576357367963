import { createSlice } from "@reduxjs/toolkit";
import {
  CreateEnterpriseCommissionSettingsRequest,
  CreateEnterpriseCommissionSettingsCommandResult,
  postEnterpriseCommission,
} from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { toast } from "sonner";
import { getEnterpriseCommissionSettingsAsync } from "State/Enterprises/Commission/CommissionSettingsSlice";
import { getTranslation, Resources } from "Translations/Resources";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const createEnterprisesCommissionSettingsAsync = createAsyncAction(
  "@enterprise/CREATE_COMMISSION_SETTINGS_REQUEST",
  "@enterprise/CREATE_COMMISSION_SETTINGS_RESPONSE",
  "@enterprise/CREATE_COMMISSION_SETTINGS_FAILURE",
)<
  CreateEnterpriseCommissionSettingsRequest,
  CreateEnterpriseCommissionSettingsCommandResult,
  Error
>();

function* createEnterprisesCommissionSettings(
  action: ReturnType<typeof createEnterprisesCommissionSettingsAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postEnterpriseCommission,
      action.payload,
    );

    if (status === 200) {
      toast.success(getTranslation(Resources.Settings.Enterprise.Save.Success));
      yield put(createEnterprisesCommissionSettingsAsync.success(data));
      yield put(getEnterpriseCommissionSettingsAsync.request());
      yield put(closeDialog());
      return;
    }

    yield put(
      createEnterprisesCommissionSettingsAsync.failure(
        mapAPIErrorResponse(error ?? data, status),
      ),
    );
  } catch (err) {
    yield put(createEnterprisesCommissionSettingsAsync.failure(err as Error));
  }
}
export function* putEnterprisesCommissionSettingsSaga() {
  yield takeLatest(
    getType(createEnterprisesCommissionSettingsAsync.request),
    createEnterprisesCommissionSettings,
  );
}

type CreateEnterpriseCommissionSettingsSliceState = {
  isLoading: boolean;
  error: Error | null;
  isDialogOpened: boolean;
};

const initialState: CreateEnterpriseCommissionSettingsSliceState = {
  isLoading: false,
  error: null,
  isDialogOpened: false,
};

export const createEnterprisesCommissionSettingsSlice = createSlice({
  initialState,
  name: "@enterprise/createEnterpriseCommissionSettings",
  reducers: {
    openDialog: state => {
      state.isDialogOpened = true;
    },
    closeDialog: state => {
      state.isDialogOpened = false;
    },
    resetError: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getType(createEnterprisesCommissionSettingsAsync.request),
      (
        state,
        action: ReturnType<
          typeof createEnterprisesCommissionSettingsAsync.request
        >,
      ) => {
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(createEnterprisesCommissionSettingsAsync.success),
      (
        state,
        action: ReturnType<
          typeof createEnterprisesCommissionSettingsAsync.success
        >,
      ) => {
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(createEnterprisesCommissionSettingsAsync.failure),
      (
        state,
        action: ReturnType<
          typeof createEnterprisesCommissionSettingsAsync.failure
        >,
      ) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});

export const { openDialog, closeDialog, resetError } =
  createEnterprisesCommissionSettingsSlice.actions;
