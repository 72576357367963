import { createSlice } from "@reduxjs/toolkit";
import {
  EntityListOfEnterpriseCommissionIndividualSettingListItemDto,
  getEnterpriseCommissionCommissionIndividualSettings,
} from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const getEnterpriseCommissionIndividualSettingsAsync = createAsyncAction(
  "@ENTERPRISE/GET_COMMISSION_INDIVIDUAL_SETTINGS_REQUEST",
  "@ENTERPRISE/GET_COMMISSION_INDIVIDUAL_SETTINGS_RESPONSE",
  "@ENTERPRISE/GET_COMMISSION_INDIVIDUAL_SETTINGS_FAILURE",
)<void, EntityListOfEnterpriseCommissionIndividualSettingListItemDto, Error>();

function* getCommissionIndividualSettings(
  action: ReturnType<
    typeof getEnterpriseCommissionIndividualSettingsAsync.request
  >,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getEnterpriseCommissionCommissionIndividualSettings,
      0,
      100,
    );

    if (status === 200) {
      yield put(getEnterpriseCommissionIndividualSettingsAsync.success(data));
      return;
    }

    yield put(
      getEnterpriseCommissionIndividualSettingsAsync.failure(
        mapAPIErrorResponse(error ?? data, status),
      ),
    );
  } catch (err) {
    yield put(
      getEnterpriseCommissionIndividualSettingsAsync.failure(err as Error),
    );
  }
}
export function* getEnterpriseCommissionIndividualSettingsSaga() {
  yield takeLatest(
    getType(getEnterpriseCommissionIndividualSettingsAsync.request),
    getCommissionIndividualSettings,
  );
}

export const getEnterpriseCommissionIndividualSettingsSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EntityListOfEnterpriseCommissionIndividualSettingListItemDto>(
      false,
    ),
  name: "getEnterpriseCommissionIndividualSettings",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EntityListOfEnterpriseCommissionIndividualSettingListItemDto>(
        false,
      ),
  },
  extraReducers: builder => {
    handleRequestCase(getEnterpriseCommissionIndividualSettingsAsync, builder);
    handleSuccessCase(getEnterpriseCommissionIndividualSettingsAsync, builder);
    handleFailureCase(getEnterpriseCommissionIndividualSettingsAsync, builder);
  },
});

export const { resetSlice } =
  getEnterpriseCommissionIndividualSettingsSlice.actions;
