import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { createReduxEnhancer } from "@sentry/react";
import { SentryEnhancerOptions } from "@sentry/react/types/redux";
import { logError } from "ErrorService";
import { Prettify } from "Models/TypeExtensions";
import { persistedAuthReducer, watchAuthSagas } from "State/Auth/AuthReducer";
import {
  persistedClientZoneReducer,
  watchClientZoneSagas,
} from "State/ClientZone/ClientZoneReducer";
import { clientReducer, watchClientSagas } from "State/Clients/ClientReducer";
import {
  persistedCodeListReducer,
  watchCodeListSaga as watchCodeListSagas,
} from "State/CodeLists/CodeListReducer";
import {
  companyReducer,
  watchCompanySagas,
} from "State/Companies/CompanyReducer";
import { cutoffReducer, watchCutoffSagas } from "State/Cutoff/CutoffReducer";
import {
  dashboardReducer,
  watchDashboardSagas,
} from "State/Dashboard/DashboardReducer";
import {
  persistedEnterpriseReducer,
  watchEnterprisesSagas,
} from "State/Enterprises/EnterpriseReducer";
import { errorSaga } from "State/Errors/ErrorsSaga";
import {
  invoiceReducer,
  watchInvoiceSagas,
} from "State/Invoice/InvoiceReducer";
import {
  orderWorkflowReducer,
  watchOrderWorkflowSagas,
} from "State/OrderWorkflow/OrderWorkflowReducer";
import { orderReducer, watchOrderSagas } from "State/Orders/OrderReducer";
import { partyReducer, watchPartySagas } from "State/Parties/PartyReducer";
import {
  servicePackageReducer,
  watchServicePackageSagas,
} from "State/ServicePackages/ServicePackagesReducer";
import { uploadReducer } from "State/Upload/UploadReducer";
import { userReducer, watchUserSagas } from "State/Users/UserReducer";
import {
  watchWorkflowSagas,
  workflowReducer,
} from "State/Workflows/WorkflowReducer";
import { produce } from "immer";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";

const combinedReducer = combineReducers({
  auth: persistedAuthReducer(),
  client: clientReducer,
  clientZone: persistedClientZoneReducer(),
  codeList: persistedCodeListReducer(),
  company: companyReducer,
  cutoff: cutoffReducer,
  dashboard: dashboardReducer,
  enterprise: persistedEnterpriseReducer(),
  invoice: invoiceReducer,
  order: orderReducer,
  orderWorkflow: orderWorkflowReducer,
  workflow: workflowReducer,
  upload: uploadReducer,
  party: partyReducer,
  servicePackage: servicePackageReducer,
  user: userReducer,
});

export type RootStateType = Prettify<ReturnType<typeof combinedReducer>>;
export type AppDispatch = typeof store.dispatch;

const persistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, combinedReducer);

const sagaMiddleware = createSagaMiddleware({
  onError: (error, errorInfo) => {
    logError(error, errorInfo);
  },
});

const sentryReduxEnhancer = createReduxEnhancer({
  stateTransformer(state) {
    return produce(state, draftState => {
      if (!draftState) {
        return;
      }

      const redaction = "REDACTED_BY_ENHANCER" as any;
      draftState.enterprise.logo.data = redaction;
      draftState.company.designSettings.data = redaction;
    });
  },
} as SentryEnhancerOptions<RootStateType>);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({ serializableCheck: false }).concat(
      sagaMiddleware,
    );
  },
  enhancers: getDefaultEnhancers => {
    return getDefaultEnhancers({
      autoBatch: { type: "tick" },
    }).concat(sentryReduxEnhancer);
  },
});

function* rootSaga() {
  yield all([
    errorSaga(),
    watchAuthSagas(),
    watchClientZoneSagas(),
    watchClientSagas(),
    watchCodeListSagas(),
    watchCompanySagas(),
    watchDashboardSagas(),
    watchEnterprisesSagas(),
    watchOrderSagas(),
    watchWorkflowSagas(),
    watchOrderWorkflowSagas(),
    watchPartySagas(),
    watchCutoffSagas(),
    watchInvoiceSagas(),
    watchServicePackageSagas(),
    watchUserSagas(),
  ]);
}

sagaMiddleware.run(rootSaga);

export const buildAppStore = () => {
  let persistor = persistStore(store);
  return { store, persistor };
};

export default buildAppStore();

export const useAppSelector: TypedUseSelectorHook<RootStateType> = useSelector;
