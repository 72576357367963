import * as React from "react";
export const FileCopyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={24}
      height={24}
    >
      <path
        d="M 8.5 5 C 6.019 5 4 7.019 4 9.5 L 4 38.5 C 4 40.981 6.019 43 8.5 43 L 12.820312 43 C 12.028313 42.177 11.453641 41.147 11.181641 40 L 8.5 40 C 7.673 40 7 39.327 7 38.5 L 7 9.5 C 7 8.673 7.673 8 8.5 8 L 11.181641 8 C 11.454641 6.853 12.028312 5.823 12.820312 5 L 8.5 5 z M 17.5 5 C 15.032499 5 13 7.0324991 13 9.5 L 13 38.5 C 13 40.967501 15.032499 43 17.5 43 L 39.5 43 C 41.967501 43 44 40.967501 44 38.5 L 44 18.5 A 1.50015 1.50015 0 0 0 43.560547 17.439453 L 31.560547 5.4394531 A 1.50015 1.50015 0 0 0 30.5 5 L 17.5 5 z M 17.5 8 L 29 8 L 29 15.5 C 29 17.967501 31.032499 20 33.5 20 L 41 20 L 41 38.5 C 41 39.346499 40.346499 40 39.5 40 L 17.5 40 C 16.653501 40 16 39.346499 16 38.5 L 16 9.5 C 16 8.6535009 16.653501 8 17.5 8 z M 32 10.121094 L 38.878906 17 L 33.5 17 C 32.653501 17 32 16.346499 32 15.5 L 32 10.121094 z M 22.5 25 A 1.50015 1.50015 0 1 0 22.5 28 L 34.5 28 A 1.50015 1.50015 0 1 0 34.5 25 L 22.5 25 z M 22.5 32 A 1.50015 1.50015 0 1 0 22.5 35 L 31.5 35 A 1.50015 1.50015 0 1 0 31.5 32 L 22.5 32 z"
        fill="currentColor"
      />
    </svg>
  );
};
