import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  EnterpriseCommissionIndividualSettingDto,
  getEnterpriseCommissionCommissionIndividualSettingEnterpriseCommissionIndividualSettingID,
} from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { getFetchStateDefaultValue } from "State/Utils";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const getCommissionIndividualSettingAsync = createAsyncAction(
  "@ENTERPRISE/GET_COMMISSION_INDIVIDUAL_SETTING_REQUEST",
  "@ENTERPRISE/GET_COMMISSION_INDIVIDUAL_SETTING_RESPONSE",
  "@ENTERPRISE/GET_COMMISSION_INDIVIDUAL_SETTING_FAILURE",
)<
  { enterpriseCommissionIndividualSettingID: number },
  EnterpriseCommissionIndividualSettingDto,
  Error
>();

function* enterpriseCommissionIndividualSetting(
  action: ReturnType<typeof getCommissionIndividualSettingAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getEnterpriseCommissionCommissionIndividualSettingEnterpriseCommissionIndividualSettingID,
      action.payload.enterpriseCommissionIndividualSettingID,
    );

    if (status === 200) {
      yield put(getCommissionIndividualSettingAsync.success(data));
      return;
    }

    yield put(
      getCommissionIndividualSettingAsync.failure(
        mapAPIErrorResponse(error ?? data, status),
      ),
    );
  } catch (err) {
    yield put(getCommissionIndividualSettingAsync.failure(err as Error));
  }
}
export function* getEnterpriseCommissionIndividualSettingSaga() {
  yield takeLatest(
    getType(getCommissionIndividualSettingAsync.request),
    enterpriseCommissionIndividualSetting,
  );
}

export const getEnterpriseCommissionIndividualSettingSlice = createSlice({
  initialState: {
    editedColor: null as string | null,
    settings:
      getFetchStateDefaultValue<EnterpriseCommissionIndividualSettingDto>(
        false,
      ),
  },
  name: "getCommissionIndividualSetting",
  reducers: {
    resetSlice: () => ({
      editedColor: null,
      settings:
        getFetchStateDefaultValue<EnterpriseCommissionIndividualSettingDto>(
          false,
        ),
    }),
    modifyColor: (state, action: PayloadAction<{ color: string | null }>) => {
      state.editedColor = action.payload.color;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getType(getCommissionIndividualSettingAsync.request),
      (
        state,
        action: ReturnType<typeof getCommissionIndividualSettingAsync.request>,
      ) => {
        state.settings.error = null;
        state.settings.isLoading = true;
      },
    );
    builder.addCase(
      getType(getCommissionIndividualSettingAsync.success),
      (
        state,
        action: ReturnType<typeof getCommissionIndividualSettingAsync.success>,
      ) => {
        state.settings.isLoading = false;
        state.settings.data = action.payload;
        state.editedColor = null;
      },
    );
    builder.addCase(
      getType(getCommissionIndividualSettingAsync.failure),
      (
        state,
        action: ReturnType<typeof getCommissionIndividualSettingAsync.failure>,
      ) => {
        state.settings.isLoading = false;
        state.settings.error = action.payload;
      },
    );
  },
});

const { resetSlice, modifyColor } =
  getEnterpriseCommissionIndividualSettingSlice.actions;

export const enterpriseCommissionIndividualSettingActions = {
  resetSlice,
  modifyColor,
};
