import { createSlice } from "@reduxjs/toolkit";
import {
  EntityListOfUserListItemDto,
  getUsersSearchAdvisorUsersInEnterprise,
} from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const searchAdvisorUsersInEnterpriseAsync = createAsyncAction(
  "@ENTERPRISE/SEARCH_ADVISOR_USERS_IN_ENTERPRISE_REQUEST",
  "@ENTERPRISE/SEARCH_ADVISOR_USERS_IN_ENTERPRISE_RESPONSE",
  "@ENTERPRISE/SEARCH_ADVISOR_USERS_IN_ENTERPRISE_FAILURE",
)<{ query: string }, EntityListOfUserListItemDto, Error>();

function* searchAdvisorUsersInEnterprise(
  action: ReturnType<typeof searchAdvisorUsersInEnterpriseAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getUsersSearchAdvisorUsersInEnterprise,
      0,
      100,
      action.payload.query,
    );

    if (status === 200) {
      yield put(searchAdvisorUsersInEnterpriseAsync.success(data));
      return;
    }

    yield put(
      searchAdvisorUsersInEnterpriseAsync.failure(
        mapAPIErrorResponse(error ?? data, status),
      ),
    );
  } catch (err) {
    yield put(searchAdvisorUsersInEnterpriseAsync.failure(err as Error));
  }
}
export function* searchAdvisorUsersInEnterpriseSaga() {
  yield takeLatest(
    getType(searchAdvisorUsersInEnterpriseAsync.request),
    searchAdvisorUsersInEnterprise,
  );
}

export const searchAdvisorUsersInEnterpriseSlice = createSlice({
  initialState: getFetchStateDefaultValue<EntityListOfUserListItemDto>(false),
  name: "SearchAdvisorUsersInEnterprise",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EntityListOfUserListItemDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(searchAdvisorUsersInEnterpriseAsync, builder);
    handleSuccessCase(searchAdvisorUsersInEnterpriseAsync, builder);
    handleFailureCase(searchAdvisorUsersInEnterpriseAsync, builder);
  },
});

export const { resetSlice: resetSearchAdvisorUsersInEnterpriseSlice } =
  searchAdvisorUsersInEnterpriseSlice.actions;
