import { Box, Divider, Typography } from "@mui/material";
import { ServiceVariantProduct } from "Api/Api";
import { BlDataTable } from "Components/Shared/DataTable/BlDataTable";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { getEnterpriseProductCountByUsersAsync } from "State/Enterprises/Products/GetEnterpriseProductCountByUsers";
import { RootStateType } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { addMonths, format } from "date-fns";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const EucsStats: React.FunctionComponent = _ => {
  const { t } = useResource();
  const dispatch = useDispatch();
  const { data } = useSelector(
    (e: RootStateType) => e.enterprise.getEnterpriseProductCountByUsers,
  );

  useEffect(() => {
    dispatch(
      getEnterpriseProductCountByUsersAsync.request({
        product: ServiceVariantProduct.EucsGarance,
        offset: 0,
        limit: 1000,
      }),
    );
  }, [dispatch]);

  return (
    <>
      {!!data && data.totalCount > 0 && (
        <>
          <Box marginBottom={3} />
          <Divider />
          <Box marginBottom={3} />
          <StyledFlex $alignItems="center" $gap={0.5}>
            <Typography variant="h2">
              {t(Resources.Settings.Enterprise.EucsStats.Title)}
            </Typography>
          </StyledFlex>
          <Typography variant="subtitle2" fontStyle={"normal"} marginBottom={1}>
            {t(Resources.Settings.Enterprise.EucsStats.Description)}
          </Typography>
          <BlDataTable
            totalCount={data.totalCount}
            rows={data.items.map(e => ({
              data: {
                userID: e.userID,
                userName: e.userName,
                count1: e.productCounts[0]?.count ?? 0,
                count2: e.productCounts[1]?.count ?? 0,
                count3: e.productCounts[2]?.count ?? 0,
                count4: e.productCounts[3]?.count ?? 0,
                count5: e.productCounts[4]?.count ?? 0,
                count6: e.productCounts[5]?.count ?? 0,
                count7: e.productCounts[6]?.count ?? 0,
                count8: e.productCounts[7]?.count ?? 0,
                count9: e.productCounts[8]?.count ?? 0,
                count10: e.productCounts[9]?.count ?? 0,
                count11: e.productCounts[10]?.count ?? 0,
                count12: e.productCounts[11]?.count ?? 0,
              },
            }))}
            rowEntityID="userID"
            getHeaderCell={column => {
              return <div>{column.label ?? column.id}</div>;
            }}
            isPaginationDisabled={true}
            rowsPerPage={1000}
            columns={[
              {
                id: "userName",
                label: "Poradce",
              },
              {
                id: "count1",
                label: format(addMonths(new Date(), -11), "M/yy").toString(),
              },
              {
                id: "count2",
                label: format(addMonths(new Date(), -10), "M/yy").toString(),
              },
              {
                id: "count3",
                label: format(addMonths(new Date(), -9), "M/yy").toString(),
              },
              {
                id: "count4",
                label: format(addMonths(new Date(), -8), "M/yy").toString(),
              },
              {
                id: "count5",
                label: format(addMonths(new Date(), -7), "M/yy").toString(),
              },
              {
                id: "count6",
                label: format(addMonths(new Date(), -6), "M/yy").toString(),
              },
              {
                id: "count7",
                label: format(addMonths(new Date(), -5), "M/yy").toString(),
              },
              {
                id: "count8",
                label: format(addMonths(new Date(), -4), "M/yy").toString(),
              },
              {
                id: "count9",
                label: format(addMonths(new Date(), -3), "M/yy").toString(),
              },
              {
                id: "count10",
                label: format(addMonths(new Date(), -2), "M/yy").toString(),
              },
              {
                id: "count11",
                label: format(addMonths(new Date(), -1), "M/yy").toString(),
              },
              {
                id: "count12",
                label: format(new Date(), "M/yy").toString(),
              },
            ]}
            getCell={(column, row) => {
              switch (column) {
                default:
                  const value = (row as any)[column as any] ?? "";

                  return <div>{value}</div>;
              }
            }}
          />
        </>
      )}
    </>
  );
};
