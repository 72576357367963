import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, Typography } from "@mui/material";
import {
  EnterpriseMode,
  EnterpriseProductInvoiceForType,
  ServiceVariantProduct,
} from "Api/Api";
import { BlSkeleton } from "Components/Shared/BlSkeleton";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { BlFormInput } from "Components/Shared/Inputs/Form/BlFormInput";
import { BlRadioButtons } from "Components/Shared/Inputs/Form/BlRadioButtons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { getEnterpriseBasicSettingsAsync } from "State/Enterprises/BasicSettings/EnterpriseBasicSettingsSlice";
import { getEnterpriseProductAdditionalInfoAsync } from "State/Enterprises/Products/GetEnterpriseProductAdditionalInfo";
import { putEnterpriseProductAdditionalInfoAsync } from "State/Enterprises/Products/PutEnterpriseProductAdditionalInfo";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { enumToCodeList } from "Utils/EnumUtils";
import { nameof } from "Utils/ObjectUtils";
import { isCompanyNumberValid } from "Utils/ValidationUtils";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { ObjectSchema, object, string } from "yup";

type FormModel = {
  invoiceForType: EnterpriseProductInvoiceForType;
  companyNumber?: string | null;
};
const StyledCompanyNumberWrapper = styled.div`
  display: grid;
  grid-template-columns: 340px auto;
  justify-content: flex-start;
  align-items: center;
  gap: ${props => props.theme.spacing(4)};
`;
export const EucsForInvoiceForm: React.FunctionComponent = _ => {
  const validationSchema: ObjectSchema<FormModel> = object().shape({
    invoiceForType: string<EnterpriseProductInvoiceForType>().required(),
    companyNumber: string().when(nameof<FormModel>("invoiceForType"), {
      is: (type: FormModel["invoiceForType"]) =>
        type === EnterpriseProductInvoiceForType.Enterprise,
      then: schema =>
        schema
          .required()
          .test(
            "companyNumber",
            t(Resources.Validation.InvalidCompanyNumber),
            (value, ctx) => {
              if (!value) {
                return false;
              }

              return isCompanyNumberValid(value);
            },
          ),
      otherwise: schema => schema.optional(),
    }),
  });

  const form = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  });

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = form;

  const dispatch = useAppDispatch();
  const { t } = useResource();
  const productInfoSettings = useAppSelector(
    state => state.enterprise.getEnterpriseProductAdditionalInfo,
  );
  const isLoading = productInfoSettings.isLoading && !productInfoSettings.data;
  const isSaving = useAppSelector(
    x => x.enterprise.putEnterpriseProductAdditionalInfo.isLoading,
  );
  const enterpriseMode = useAppSelector(
    x => x.enterprise.basicSettings.settings.data?.mode,
  );

  const invoiceForTypeValue = watch("invoiceForType");
  const companyNumberValue = watch("companyNumber");

  const isSubmitDisabled =
    productInfoSettings.data?.invoiceForType === invoiceForTypeValue &&
    productInfoSettings.data?.companyNumber === companyNumberValue;

  useEffect(() => {
    dispatch(getEnterpriseBasicSettingsAsync.request());
    dispatch(
      getEnterpriseProductAdditionalInfoAsync.request({
        product: ServiceVariantProduct.EucsGarance,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    reset({
      invoiceForType:
        productInfoSettings.data?.invoiceForType ??
        EnterpriseProductInvoiceForType.Enterprise,
      companyNumber: productInfoSettings.data?.companyNumber ?? null,
    });
  }, [reset, productInfoSettings.data]);

  const resetForm = () => {
    reset({
      invoiceForType:
        productInfoSettings.data?.invoiceForType ??
        EnterpriseProductInvoiceForType.Enterprise,
      companyNumber: productInfoSettings.data?.companyNumber ?? null,
    });
  };

  const onSubmit = (data: FormModel) => {
    dispatch(
      putEnterpriseProductAdditionalInfoAsync.request({
        invoiceForType: data.invoiceForType,
        companyNumber: data.companyNumber,
        product: ServiceVariantProduct.EucsGarance,
      }),
    );
  };

  if (enterpriseMode === EnterpriseMode.Strict) {
    return <></>;
  }

  return (
    <>
      <Box marginBottom={3} />
      <Divider />
      <Box marginBottom={3} />

      <form onSubmit={handleSubmit(onSubmit)} onReset={() => resetForm()}>
        <StyledFlex $alignItems="center" $gap={0.5}>
          <Typography variant="h2">
            {t(Resources.Settings.Enterprise.EucsMode.Title)}
          </Typography>
        </StyledFlex>
        <Typography variant="subtitle2" fontStyle={"normal"} marginBottom={1}>
          {t(Resources.Settings.Enterprise.EucsMode.Description)}
        </Typography>

        {isLoading ? (
          <BlSkeleton height={60} />
        ) : (
          <>
            <BlRadioButtons
              control={control}
              errors={errors}
              name={"invoiceForType"}
              codeList={enumToCodeList(
                EnterpriseProductInvoiceForType,
                Resources.Transactions.EnterpriseProductInvoiceForType,
              )}
            />
            {invoiceForTypeValue ===
              EnterpriseProductInvoiceForType.Enterprise && (
              <StyledCompanyNumberWrapper>
                <BlFormInput
                  control={control}
                  errors={errors}
                  name={"companyNumber"}
                  label={t(
                    Resources.Settings.Enterprise.EucsMode.CompanyNumber,
                  )}
                  mask={"00000000"}
                ></BlFormInput>
              </StyledCompanyNumberWrapper>
            )}
          </>
        )}

        <StyledFlex $alignItems="center" $gap={2}>
          <BlButton
            color="primary"
            type="submit"
            disabled={isSubmitDisabled}
            isLoading={isSaving}
          >
            {t(Resources.Common.Save_FirstUppercase)}
          </BlButton>
          <BlButton type="button" onClick={() => resetForm()}>
            {t(Resources.Common.Reset)}
          </BlButton>
        </StyledFlex>
      </form>
    </>
  );
};
