import {
  EarlyDoneRecurringPaymentOrderCommandCommandResult,
  postOrdersPublicIDWorkflowEarlyDoneRecurringPaymentOrder,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { getOrderWorkflowStepsAsync } from "State/OrderWorkflow/Slices/OrderWorkflowStepsSlice";
import { getOrderDetailAsync } from "State/Orders/Detail/OrderDetailSlice";
import { getPaymentCalendarItemsAsync } from "State/OrderWorkflow/Slices/GetPaymentCalendarItemsSlice";

export const earlyDoneRecurringPaymentOrderAsync = createAsyncAction(
  "@orderWorkflow/EARLY_DONE_RECURRING_PAYMENT_ORDER_REQUEST",
  "@orderWorkflow/EARLY_DONE_RECURRING_PAYMENT_ORDER_RESPONSE",
  "@orderWorkflow/EARLY_DONE_RECURRING_PAYMENT_ORDER_FAILURE",
)<
  {
    publicID: string;
    setIsOpen: (value: boolean) => void;
  },
  EarlyDoneRecurringPaymentOrderCommandCommandResult,
  Error
>();

function* earlyDoneRecurringPaymentOrder(
  action: ReturnType<typeof earlyDoneRecurringPaymentOrderAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postOrdersPublicIDWorkflowEarlyDoneRecurringPaymentOrder,
      action.payload.publicID,
    );

    if (status === 200) {
      yield put(earlyDoneRecurringPaymentOrderAsync.success(data));
      yield put(
        getOrderWorkflowStepsAsync.request({
          publicID: action.payload.publicID,
        }),
      );
      yield put(
        getOrderDetailAsync.request({
          publicID: action.payload.publicID,
        }),
      );
      yield put(
        getPaymentCalendarItemsAsync.request({
          publicID: action.payload.publicID,
        }),
      );
      return;
    }

    yield put(
      earlyDoneRecurringPaymentOrderAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );

    action.payload.setIsOpen(false);
  } catch (err) {
    yield put(earlyDoneRecurringPaymentOrderAsync.failure(err as Error));
  }
}
export function* earlyDoneRecurringPaymentOrderSaga() {
  yield takeLatest(
    getType(earlyDoneRecurringPaymentOrderAsync.request),
    earlyDoneRecurringPaymentOrder,
  );
}

export const earlyDoneRecurringPaymentOrderSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EarlyDoneRecurringPaymentOrderCommandCommandResult>(
      false,
    ),
  name: "@orderWorkflow/earlyDoneRecurringPaymentOrder",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EarlyDoneRecurringPaymentOrderCommandCommandResult>(
        false,
      ),
  },
  extraReducers: builder => {
    handleRequestCase(earlyDoneRecurringPaymentOrderAsync, builder);
    handleSuccessCase(earlyDoneRecurringPaymentOrderAsync, builder);
    handleFailureCase(earlyDoneRecurringPaymentOrderAsync, builder);
  },
});

export const { resetSlice } = earlyDoneRecurringPaymentOrderSlice.actions;
