import { Stack, Typography } from "@mui/material";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { BlDataTable } from "Components/Shared/DataTable/BlDataTable";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import {
  closeDialog,
  openDialog,
} from "State/Enterprises/Commission/CreateCommissionIndividualSettingsSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { formatDate, toLocalTimeZoneString } from "Utils/DateUtils";
import { useCallback, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { formatPercentage } from "Utils/NumberUtils";
import { getEnterpriseCommissionIndividualSettingsAsync } from "State/Enterprises/Commission/CommissionIndividualSettingsSlice";
import {
  EnterpriseCommissionIndividualSettingListItemDto,
  EnterpriseMode,
} from "Api/Api";
import { CommissionIndividualDialogForm } from "Components/Settings/Commission/CommisionIndividualDialogForm";
import { BlTextInput } from "Components/Shared/Inputs/BlTextInput";
import { StyledSearchIcon } from "Components/Shared/Inputs/Autocomplete/BlAutocompleteStyles";
import { TrashIcon } from "Components/Shared/Icons";
import { BlConfirmButton } from "Components/Shared/Buttons/BlConfirmButton";
import { deactivateCommissionIndividualSettingAsync } from "State/Enterprises/Commission/DeactivateCommissionIndividualSettingsSlice";
import { StyledValidationText } from "Components/Shared/FormStyles";

const ActualButton = styled(BlButton)`
  height: 32px;
  border-color: ${props => props.theme.colors.border};
  color: ${props => props.theme.palette.common.black};
  pointer-events: none;
  min-width: 170px;
`;

export const CommissionIndividualList: React.FunctionComponent = _ => {
  const dispatch = useAppDispatch();
  const { t } = useResource();
  const theme = useTheme();
  const { data: allCommissions } = useAppSelector(
    state => state.enterprise.commissionIndividualSettings,
  );
  const { isDialogOpened } = useAppSelector(
    state => state.enterprise.createCommissionIndividualSetting,
  );

  const enterpriseMode = useAppSelector(
    x => x.enterprise.basicSettings.settings.data?.mode,
  );

  const [filteredCommissions, setFilteredCommissions] = useState<
    EnterpriseCommissionIndividualSettingListItemDto[] | undefined
  >(allCommissions?.items ?? []);

  useEffect(() => {
    setFilteredCommissions(allCommissions?.items);
  }, [allCommissions]);

  const handleAutocompleteSearch = useCallback(
    (query: string) => {
      if (query && query.length > 0) {
        const filteredCommissions = allCommissions?.items?.filter(commission =>
          commission.userName.toLowerCase().includes(query.toLowerCase()),
        );
        setFilteredCommissions(filteredCommissions);
      } else {
        setFilteredCommissions(allCommissions?.items);
      }
    },
    [allCommissions],
  );

  useEffect(() => {
    dispatch(getEnterpriseCommissionIndividualSettingsAsync.request());
  }, [dispatch]);

  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <div>
          <StyledFlex $alignItems="center" $gap={0.5}>
            <Typography variant="h2">
              {t(Resources.Settings.CommissionIndividual.Title)}
            </Typography>
            <BlDefaultTooltip
              title={t(Resources.Settings.CommissionIndividual.Tooltip)}
            />
          </StyledFlex>
          <Typography variant="subtitle2" fontStyle={"normal"} marginBottom={1}>
            {t(Resources.Settings.CommissionIndividual.Description)}
          </Typography>

          {enterpriseMode === EnterpriseMode.Free && (
            <StyledValidationText $fontSize="14px">
              {t(Resources.Settings.Commission.EnterpriseModeFree)}
            </StyledValidationText>
          )}
        </div>

        <div>
          <BlButton color="primary" onClick={() => dispatch(openDialog())}>
            {t(Resources.CommissionIndividual.Create.OpenDialogButton)}
          </BlButton>

          {isDialogOpened && (
            <CommissionIndividualDialogForm
              onClosed={() => dispatch(closeDialog())}
            />
          )}
        </div>
      </Stack>

      <Stack flexDirection="row" justifyContent="space-between" marginTop={1}>
        <BlTextInput
          label={t(Resources.Settings.CommissionIndividual.Filter)}
          placeholder={t(
            Resources.Settings.CommissionIndividual.FilterPlaceholder,
          )}
          onInputChange={e => {
            handleAutocompleteSearch(e.currentTarget.value);
          }}
          InputProps={{
            startAdornment: <StyledSearchIcon style={{ marginLeft: 0 }} />,
          }}
        ></BlTextInput>
      </Stack>

      <BlDataTable
        totalCount={filteredCommissions?.length}
        rows={filteredCommissions?.map(e => ({ data: e })) ?? []}
        rowEntityID="enterpriseCommissionIndividualSettingID"
        isPaginationDisabled={true}
        getHeaderCell={column => {
          return <div>{column.label ?? column.id}</div>;
        }}
        columns={[
          { id: "userName", label: "Poradce" },
          { id: "dateFrom", label: "Období platnosti" },
          { id: "enterpriseCommissionRate", label: "Podíl HSP" },
          { id: "advisorCommissionRate", label: "Podíl poradce" },
          { id: "secondUserName", label: "2. poradce" },
          { id: "secondAdvisorCommissionRate", label: "Podíl 2. poradce" },
          { id: "isActive", label: "" },
          { id: "enterpriseCommissionIndividualSettingID", label: "" },
        ]}
        getCell={(column, row) => {
          switch (column) {
            case "dateFrom":
              return (
                <span>
                  {formatDate(row.dateFrom)} -{" "}
                  {!!row.dateTo ? formatDate(row.dateTo) : ""}
                </span>
              );

            case "enterpriseCommissionRate":
            case "advisorCommissionRate":
            case "secondAdvisorCommissionRate":
              return <span>{formatPercentage(row[column])}</span>;

            case "isActive":
              return row.isActive ||
                row.isActiveTodayEnd ||
                row.isActiveInPast ? (
                <>
                  <ActualButton color="secondary">
                    {t(
                      row.isActiveTodayEnd
                        ? Resources.Settings.CommissionIndividual.TodayEnd
                        : row.isActiveInPast
                          ? Resources.Settings.CommissionIndividual.ActiveInPast
                          : Resources.Common.Actual,
                    )}
                  </ActualButton>
                </>
              ) : (
                <></>
              );

            case "enterpriseCommissionIndividualSettingID":
              return (row.isActive || row.isActiveInFuture) && !row.dateTo ? (
                <>
                  <BlConfirmButton
                    variant="default"
                    modalTitle={t(
                      row.isActiveInFuture
                        ? Resources.Settings.CommissionIndividual.Delete.Title
                        : Resources.Settings.CommissionIndividual.Deactivate
                            .Title,
                    )}
                    modalContent={t(
                      row.isActiveInFuture
                        ? Resources.Settings.CommissionIndividual.Delete.Text
                        : Resources.Settings.CommissionIndividual.Deactivate
                            .Text,
                    )}
                    tooltip={t(
                      row.isActiveInFuture
                        ? Resources.Settings.CommissionIndividual.Delete.Tooltip
                        : Resources.Settings.CommissionIndividual.Deactivate
                            .Tooltip,
                    )}
                    onResult={r => {
                      if (r) {
                        dispatch(
                          deactivateCommissionIndividualSettingAsync.request({
                            enterpriseCommissionIndividualSettingID:
                              row.enterpriseCommissionIndividualSettingID,
                            request: {
                              dateTo: toLocalTimeZoneString(new Date()),
                            },
                          }),
                        );
                      }
                    }}
                  >
                    <TrashIcon
                      color={theme.palette.error.main}
                      height={18}
                      width={18}
                    />
                  </BlConfirmButton>
                </>
              ) : (
                <></>
              );

            default:
              const value = (row as any)[column as any] ?? "";

              return <div>{value}</div>;
          }
        }}
      />
    </>
  );
};
