import { createSlice } from "@reduxjs/toolkit";
import {
  EnterpriseCommissionIndividualSettingsDeactivateRequest,
  DeactivateEnterpriseCommissionIndividualSettingsCommandResult,
  patchEnterpriseCommissionDeactivateCommissionIndividualSettingEnterpriseCommissionIndividualSettingID,
} from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { toast } from "sonner";
import { getEnterpriseCommissionIndividualSettingsAsync } from "State/Enterprises/Commission/CommissionIndividualSettingsSlice";
import { getTranslation, Resources } from "Translations/Resources";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const deactivateCommissionIndividualSettingAsync = createAsyncAction(
  "@enterprise/DEACTIVATE_COMMISSION_INDIVIDUAL_SETTINGS_REQUEST",
  "@enterprise/DEACTIVATE_COMMISSION_INDIVIDUAL_SETTINGS_RESPONSE",
  "@enterprise/DEACTIVATE_COMMISSION_INDIVIDUAL_SETTINGS_FAILURE",
)<
  {
    request: EnterpriseCommissionIndividualSettingsDeactivateRequest;
    enterpriseCommissionIndividualSettingID: number;
  },
  DeactivateEnterpriseCommissionIndividualSettingsCommandResult,
  Error
>();

function* deactivateCommissionIndividualSetting(
  action: ReturnType<typeof deactivateCommissionIndividualSettingAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      patchEnterpriseCommissionDeactivateCommissionIndividualSettingEnterpriseCommissionIndividualSettingID,
      action.payload.request,
      action.payload.enterpriseCommissionIndividualSettingID,
    );

    if (status === 200) {
      toast.success(getTranslation(Resources.Settings.Enterprise.Save.Success));
      yield put(deactivateCommissionIndividualSettingAsync.success(data));
      yield put(getEnterpriseCommissionIndividualSettingsAsync.request());
      return;
    }

    yield put(
      deactivateCommissionIndividualSettingAsync.failure(
        mapAPIErrorResponse(error ?? data, status),
      ),
    );
  } catch (err) {
    yield put(deactivateCommissionIndividualSettingAsync.failure(err as Error));
  }
}
export function* deactivateCommissionIndividualSettingSaga() {
  yield takeLatest(
    getType(deactivateCommissionIndividualSettingAsync.request),
    deactivateCommissionIndividualSetting,
  );
}

type DeactivateEnterpriseCommissionIndividualSettingSliceState = {
  isLoading: boolean;
  error: Error | null;
};

const initialState: DeactivateEnterpriseCommissionIndividualSettingSliceState =
  {
    isLoading: false,
    error: null,
  };

export const deactivateCommissionIndividualSettingSlice = createSlice({
  initialState,
  name: "@enterprise/deactivateEnterpriseCommissionIndividualSetting",
  reducers: {
    resetError: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getType(deactivateCommissionIndividualSettingAsync.request),
      (
        state,
        action: ReturnType<
          typeof deactivateCommissionIndividualSettingAsync.request
        >,
      ) => {
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(deactivateCommissionIndividualSettingAsync.success),
      (
        state,
        action: ReturnType<
          typeof deactivateCommissionIndividualSettingAsync.success
        >,
      ) => {
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(deactivateCommissionIndividualSettingAsync.failure),
      (
        state,
        action: ReturnType<
          typeof deactivateCommissionIndividualSettingAsync.failure
        >,
      ) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});

export const { resetError } =
  deactivateCommissionIndividualSettingSlice.actions;
