import { Box, Divider } from "@mui/material";
import { CommissionIndividualList } from "Components/Settings/Commission/CommissionIndividualList";
import { CommissionList } from "Components/Settings/Commission/CommissionList";

export const CommissionsSettings: React.FunctionComponent = _ => {
  return (
    <>
      <CommissionList></CommissionList>
      <Box marginBottom={5} />
      <Divider />
      <Box marginBottom={5} />
      <CommissionIndividualList></CommissionIndividualList>
    </>
  );
};
