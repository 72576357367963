import { createSlice } from "@reduxjs/toolkit";
import {
  EnterpriseCommissionIndividualSettingsCreateRequest,
  CreateEnterpriseCommissionIndividualSettingsCommandResult,
  postEnterpriseCommissionCommissionIndividualSetting,
} from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { toast } from "sonner";
import { getEnterpriseCommissionIndividualSettingsAsync } from "State/Enterprises/Commission/CommissionIndividualSettingsSlice";
import { getTranslation, Resources } from "Translations/Resources";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const createCommissionIndividualSettingAsync = createAsyncAction(
  "@enterprise/CREATE_COMMISSION_INDIVIDUAL_SETTINGS_REQUEST",
  "@enterprise/CREATE_COMMISSION_INDIVIDUAL_SETTINGS_RESPONSE",
  "@enterprise/CREATE_COMMISSION_INDIVIDUAL_SETTINGS_FAILURE",
)<
  EnterpriseCommissionIndividualSettingsCreateRequest,
  CreateEnterpriseCommissionIndividualSettingsCommandResult,
  Error
>();

function* createCommissionIndividualSetting(
  action: ReturnType<typeof createCommissionIndividualSettingAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postEnterpriseCommissionCommissionIndividualSetting,
      action.payload,
    );

    if (status === 200) {
      toast.success(getTranslation(Resources.Settings.Enterprise.Save.Success));
      yield put(createCommissionIndividualSettingAsync.success(data));
      yield put(getEnterpriseCommissionIndividualSettingsAsync.request());
      yield put(closeDialog());
      return;
    }

    yield put(
      createCommissionIndividualSettingAsync.failure(
        mapAPIErrorResponse(error ?? data, status),
      ),
    );
  } catch (err) {
    yield put(createCommissionIndividualSettingAsync.failure(err as Error));
  }
}
export function* createCommissionIndividualSettingSaga() {
  yield takeLatest(
    getType(createCommissionIndividualSettingAsync.request),
    createCommissionIndividualSetting,
  );
}

type CreateEnterpriseCommissionIndividualSettingSliceState = {
  isLoading: boolean;
  error: Error | null;
  isDialogOpened: boolean;
};

const initialState: CreateEnterpriseCommissionIndividualSettingSliceState = {
  isLoading: false,
  error: null,
  isDialogOpened: false,
};

export const createCommissionIndividualSettingSlice = createSlice({
  initialState,
  name: "@enterprise/createEnterpriseCommissionIndividualSetting",
  reducers: {
    openDialog: state => {
      state.isDialogOpened = true;
    },
    closeDialog: state => {
      state.isDialogOpened = false;
    },
    resetError: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getType(createCommissionIndividualSettingAsync.request),
      (
        state,
        action: ReturnType<
          typeof createCommissionIndividualSettingAsync.request
        >,
      ) => {
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(createCommissionIndividualSettingAsync.success),
      (
        state,
        action: ReturnType<
          typeof createCommissionIndividualSettingAsync.success
        >,
      ) => {
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(createCommissionIndividualSettingAsync.failure),
      (
        state,
        action: ReturnType<
          typeof createCommissionIndividualSettingAsync.failure
        >,
      ) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});

export const { openDialog, closeDialog, resetError } =
  createCommissionIndividualSettingSlice.actions;
