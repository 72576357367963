import { combineReducers } from "@reduxjs/toolkit";
import {
  completeInvoiceIssuanceSaga,
  completeInvoiceIssuanceSlice,
} from "State/OrderWorkflow/Slices/CompleteInvoiceIssuanceSlice";
import {
  completeInvoicePaymentSlice,
  completeInvoicePaymentSaga,
} from "State/OrderWorkflow/Slices/CompleteInvoicePaymentSlice";
import {
  getOrderWorkflowStepsSaga,
  getOrderWorkflowStepsSlice,
} from "State/OrderWorkflow/Slices/OrderWorkflowStepsSlice";
import {
  sendReminderSaga,
  sendReminderSlice,
} from "State/OrderWorkflow/Slices/SendClientReviewReminderSlice";
import {
  sendInvoicePaymentReminderSaga,
  sendInvoicePaymentReminderSlice,
} from "State/OrderWorkflow/Slices/SendInvoicePaymentReminder";
import {
  completeDraftSaga,
  completeDraftSlice,
} from "State/OrderWorkflow/Slices/CompleteDraftSlice";
import { all } from "typed-redux-saga";
import {
  getEnterpriseInvoiceIssuanceAndPaymentSaga,
  getEnterpriseInvoiceIssuanceAndPaymentSlice,
} from "State/OrderWorkflow/Slices/GetEnterpriseInvoiceIssuanceAndPayment";
import {
  getPaymentCalendarItemsSaga,
  getPaymentCalendarItemsSlice,
} from "State/OrderWorkflow/Slices/GetPaymentCalendarItemsSlice";
import {
  cancelPaymentCalendarItemSaga,
  cancelPaymentCalendarItemSlice,
} from "State/OrderWorkflow/Slices/CancelPaymentCalendarItemSlice";
import {
  payPaymentCalendarItemSaga,
  payPaymentCalendarItemSlice,
} from "State/OrderWorkflow/Slices/PayPaymentCalendarItemSlice";
import {
  sendPaymentCalendarItemReminderSaga,
  sendPaymentCalendarItemReminderSlice,
} from "State/OrderWorkflow/Slices/SendPaymentCalendarItemReminderSlice";
import {
  sendClientAssignmentEmailSlice,
  sendClientAssignmentEmailSaga,
} from "State/OrderWorkflow/Slices/SendClientAssignmentEmailSlice";
import { completeClientPrepaidPaymentApprovalSaga } from "State/OrderWorkflow/Slices/CompleteClientPrepaidPaymentApprovalSlice";
import {
  sendAndPayPrepaidPaymentCalendarItemSaga,
  sendAndPayPrepaidPaymentCalendarItemSlice,
} from "State/OrderWorkflow/Slices/SendAndPayPrepaidPaymentCalendarItemSlice";
import {
  paymentCalendarItemPrepaidOrderInvoiceReminderSaga,
  paymentCalendarItemPrepaidOrderInvoiceReminderSlice,
} from "State/OrderWorkflow/Slices/PaymentCalendarItemPrepaidOrderInvoiceReminderSlice";
import {
  earlyDoneRecurringPaymentOrderSaga,
  earlyDoneRecurringPaymentOrderSlice,
} from "State/OrderWorkflow/Slices/EarlyDoneRecurringPaymentOrdereSlice";

export const orderWorkflowReducer = combineReducers({
  workflowSteps: getOrderWorkflowStepsSlice.reducer,
  completeDraft: completeDraftSlice.reducer,
  sendReminder: sendReminderSlice.reducer,
  completeInvoiceIssuance: completeInvoiceIssuanceSlice.reducer,
  completeInvoicePayment: completeInvoicePaymentSlice.reducer,
  completeClientPrepaidPaymentApproval: completeInvoicePaymentSlice.reducer,
  sendInvoicePaymentReminder: sendInvoicePaymentReminderSlice.reducer,
  enterpriseInvoiceIssuanceAndPayment:
    getEnterpriseInvoiceIssuanceAndPaymentSlice.reducer,
  paymentCalendarItems: getPaymentCalendarItemsSlice.reducer,
  cancelPaymentCalendarItem: cancelPaymentCalendarItemSlice.reducer,
  payPaymentCalendarItem: payPaymentCalendarItemSlice.reducer,
  sendPaymentCalendarItemReminder: sendPaymentCalendarItemReminderSlice.reducer,
  paymentCalendarItemPrepaidOrderInvoiceReminder:
    paymentCalendarItemPrepaidOrderInvoiceReminderSlice.reducer,
  sendClientAssignmentEmail: sendClientAssignmentEmailSlice.reducer,
  earlyDoneRecurringPayementOrder: earlyDoneRecurringPaymentOrderSlice.reducer,
  sendAndPayPrepaidPaymentCalendarItem:
    sendAndPayPrepaidPaymentCalendarItemSlice.reducer,
});

export function* watchOrderWorkflowSagas() {
  yield all([
    getOrderWorkflowStepsSaga(),
    completeDraftSaga(),
    sendReminderSaga(),
    completeInvoiceIssuanceSaga(),
    completeInvoicePaymentSaga(),
    completeClientPrepaidPaymentApprovalSaga(),
    sendInvoicePaymentReminderSaga(),
    getEnterpriseInvoiceIssuanceAndPaymentSaga(),
    getPaymentCalendarItemsSaga(),
    cancelPaymentCalendarItemSaga(),
    payPaymentCalendarItemSaga(),
    sendPaymentCalendarItemReminderSaga(),
    sendClientAssignmentEmailSaga(),
    sendAndPayPrepaidPaymentCalendarItemSaga(),
    paymentCalendarItemPrepaidOrderInvoiceReminderSaga(),
    earlyDoneRecurringPaymentOrderSaga(),
  ]);
}
