import { combineReducers } from "@reduxjs/toolkit";
import {
  cutoffSendPaymentsSlice,
  cutoffSendPaymentsSaga,
} from "State/Cutoff/CutoffSendPayments";
import {
  getCutoffDetailPdfSlice,
  getCutoffDetailPdfSaga,
} from "State/Cutoff/GetCutoffDetailPdf";
import {
  getCutoffInvoiceForSupplierCompanyByUserPdfsSlice,
  getCutoffInvoiceForSupplierCompanyByUserPdfsSaga,
} from "State/Cutoff/GetCutoffInvoiceForSupplierCompanyByUserPdfsSlice";
import {
  getCutoffsListSaga,
  getCutoffsListSlice,
} from "State/Cutoff/GetCutoffsListSlice";
import {
  putEnterpriseCutoffProductPriceSettingsSaga,
  putEnterpriseCutoffProductPriceSettingsSlice,
} from "State/Cutoff/PutEnterpriseCutoffProductPriceSettings";
import {
  getNextCutoffDateFromSlice,
  getNextCutoffDateFromSaga,
} from "State/Cutoff/GetNextCutoffDateFrom";
import { postCutoffSlice, postCutoffSaga } from "State/Cutoff/PostCutoffSlice";
import { all } from "typed-redux-saga";
import {
  getCutoffUserSupplierCompaniesSaga,
  getCutoffUserSupplierCompaniesSlice,
} from "State/Cutoff/List/GetCutoffUserSupplierCompaniesSlice";
import {
  getEnterpriseCutoffProductPriceSettingsSaga,
  getEnterpriseCutoffProductPriceSettingsSlice,
} from "State/Cutoff/GetEnterpriseCutoffProductPriceSettings";
import {
  getCutoffInvoiceForClientByOrderPdfsSaga,
  getCutoffInvoiceForClientByOrderPdfsSlice,
} from "State/Cutoff/GetCutoffInvoiceForClientByOrderPdfsSlice";

export const cutoffReducer = combineReducers({
  list: getCutoffsListSlice.reducer,
  nextDateFrom: getNextCutoffDateFromSlice.reducer,
  post: postCutoffSlice.reducer,
  zipCutoffInvoiceForSupplierCompanyByUserPdfsSlice:
    getCutoffInvoiceForSupplierCompanyByUserPdfsSlice.reducer,
  zipCutoffInvoiceForClientByOrderPdfsSlice:
    getCutoffInvoiceForClientByOrderPdfsSlice.reducer,
  pdf: getCutoffDetailPdfSlice.reducer,
  sendPayments: cutoffSendPaymentsSlice.reducer,
  companies: getCutoffUserSupplierCompaniesSlice.reducer,
  putEnterpriseCutoffProductPriceSettings:
    putEnterpriseCutoffProductPriceSettingsSlice.reducer,
  getEnterpriseCutoffProductPriceSettings:
    getEnterpriseCutoffProductPriceSettingsSlice.reducer,
});

export function* watchCutoffSagas() {
  yield all([
    getCutoffsListSaga(),
    getNextCutoffDateFromSaga(),
    postCutoffSaga(),
    getCutoffInvoiceForSupplierCompanyByUserPdfsSaga(),
    getCutoffInvoiceForClientByOrderPdfsSaga(),
    getCutoffDetailPdfSaga(),
    cutoffSendPaymentsSaga(),
    getCutoffUserSupplierCompaniesSaga(),
    putEnterpriseCutoffProductPriceSettingsSaga(),
    getEnterpriseCutoffProductPriceSettingsSaga(),
  ]);
}
