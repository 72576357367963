import { EnterpriseMode } from "Api/Api";
import { CompaniesList } from "Components/Companies/CompaniesList";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { CommissionsSettings } from "Components/Settings/Commission/CommissionsSettings";
import { Cutoffs } from "Components/Settings/Cutoff/Cutoffs";
import { EnterpriseSettings } from "Components/Settings/Enterprise/EnterpriseSettings";
import { Invoices } from "Components/Settings/Invoices/Invoices";
import { ServicePackages } from "Components/Settings/Services/ServicePackages/List/ServicePackages";
import { ServicesForm } from "Components/Settings/Services/ServicesForms";
import { BlTabs, TabType } from "Components/Shared/BlTabs";
import { PageTitle } from "Components/Shared/PageTitle";
import {
  StyledContentWrapper,
  StyledPageWrapper,
} from "Components/Shared/StyledComponents";
import { useHasCompaniesAccess } from "Hooks/AccessRights/useHasCompaniesAccess";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { AccessRightCodes } from "Models/AccessRightCodes";
import { getEnterpriseBasicSettingsAsync } from "State/Enterprises/BasicSettings/EnterpriseBasicSettingsSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

type Props = {};

export const SettingsPage: React.FunctionComponent<Props> = props => {
  const { t } = useResource();
  const defaultTab = 0;

  const { tab } = useParams() as { tab?: string };
  const [activeTabID, setActiveTabID] = useState<number>(defaultTab);

  const dispatch = useAppDispatch();
  const enterpriseMode = useAppSelector(
    x => x.enterprise.basicSettings.settings.data?.mode,
  );

  useEffect(() => {
    dispatch(getEnterpriseBasicSettingsAsync.request());
  }, [dispatch]);

  const accessRightCodes =
    useAppSelector(e => e.auth.session.user?.accessRightCodes) || [];

  let accessTabs = new Array<TabType>();
  let tabID = 0;
  const hasSettingsEnterprise = accessRightCodes.some(
    x => x === AccessRightCodes.SettingsEnterprise,
  );
  const hasSettingsAdvisor = accessRightCodes.some(
    x => x === AccessRightCodes.SettingsAdvisor,
  );

  const hasCompaniesAccess = useHasCompaniesAccess();

  if (hasSettingsEnterprise) {
    accessTabs.push({
      tabID: tabID++,
      name: t(Resources.Settings.Enterprise.Url),
      title: t(Resources.Settings.Enterprise.Title),
      child: <EnterpriseSettings />,
      url: getPath(AppRouting.Settings, t(Resources.Settings.Enterprise.Url)),
    });
  }

  if (
    hasSettingsEnterprise ||
    (hasSettingsAdvisor && enterpriseMode === EnterpriseMode.Free)
  ) {
    accessTabs.push({
      tabID: tabID++,
      name: t(Resources.Settings.Services.Url),
      title: t(Resources.Settings.Services.Title),
      child: <ServicesForm />,
      url: getPath(AppRouting.Settings, t(Resources.Settings.Services.Url)),
    });
  }

  if (hasSettingsEnterprise || hasSettingsAdvisor) {
    accessTabs.push({
      tabID: tabID++,
      name: t(Resources.Settings.ServicePackages.Url),
      title: t(Resources.Settings.ServicePackages.Title),
      child: <ServicePackages />,
      url: getPath(
        AppRouting.Settings,
        t(Resources.Settings.ServicePackages.Url),
      ),
    });
  }

  if (hasSettingsEnterprise) {
    accessTabs.push({
      tabID: tabID++,
      name: t(Resources.Settings.Commission.Url),
      title: t(Resources.Settings.Commission.Title),
      child: <CommissionsSettings />,
      url: getPath(AppRouting.Settings, t(Resources.Settings.Commission.Url)),
    });
  }

  if (hasCompaniesAccess) {
    accessTabs.push({
      tabID: tabID++,
      name: t(Resources.Settings.Companies.Url),
      title: t(Resources.Settings.Companies.Title),
      child: <CompaniesList />,
      url: getPath(AppRouting.Settings, t(Resources.Settings.Companies.Url)),
    });
  }

  if (hasSettingsEnterprise) {
    accessTabs.push({
      tabID: tabID++,
      name: t(Resources.Settings.Cutoff.Url),
      title: t(Resources.Settings.Cutoff.Title),
      child: <Cutoffs />,
      url: getPath(AppRouting.Settings, t(Resources.Settings.Cutoff.Url)),
    });
  }

  if (hasSettingsEnterprise || hasSettingsAdvisor) {
    accessTabs.push({
      tabID: tabID++,
      name: t(Resources.Settings.Invoices.Url),
      title: t(Resources.Settings.Invoices.Title),
      child: <Invoices />,
      url: getPath(AppRouting.Settings, t(Resources.Settings.Invoices.Url)),
    });
  }

  const [tabs] = useState<TabType[]>(accessTabs);

  useEffect(() => {
    setActiveTabID(
      (tab
        ? tabs.filter(x => x.name === tab?.toLowerCase())[0]?.tabID
        : defaultTab) ?? defaultTab,
    );
  }, [tab, tabs]);

  return (
    <AuthenticatedLayout title={t(Resources.Settings.PageName)}>
      <StyledPageWrapper>
        <PageTitle title={t(Resources.Settings.PageName)}></PageTitle>

        <StyledContentWrapper>
          <BlTabs activeTabID={activeTabID} tabs={tabs}></BlTabs>
        </StyledContentWrapper>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};
