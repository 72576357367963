import {
  OrderDto,
  OrderPaymentFrequency,
  PaymentCalendarItemStatus,
  RelationType,
} from "Api/Api";
import { OrderDetailIconButton } from "Components/Orders/Detail/Styles";
import { BlConfirmDialog } from "Components/Shared/Dialogs/BlConfirmDialog";
import { CloseIcon } from "Components/Shared/Icons/Close";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { earlyDoneRecurringPaymentOrderAsync } from "State/OrderWorkflow/Slices/EarlyDoneRecurringPaymentOrdereSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { useState } from "react";

type Props = {
  order: OrderDto;
};

export const EarlyDoneOrderRecurringPaymentIcon: React.FunctionComponent<
  Props
> = props => {
  const { order } = props;
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useResource();

  const isReadOnly = order.userRelationType === RelationType.Visibility;

  const { isLoading: isLoadingData, data: paymentCalendarItems } =
    useAppSelector(e => e.orderWorkflow.paymentCalendarItems);

  const { isLoading: isLoadingSend } = useAppSelector(
    e => e.orderWorkflow.earlyDoneRecurringPayementOrder,
  );

  if (
    order.paymentFrequency === OrderPaymentFrequency.Single ||
    isLoadingData
  ) {
    return <></>;
  }

  const hasSomeCreated = paymentCalendarItems?.items.some(x =>
    [PaymentCalendarItemStatus.Created].includes(x.status),
  );

  if (!hasSomeCreated) {
    return <></>;
  }

  const hasSomeSentToClient = paymentCalendarItems?.items.some(x =>
    [PaymentCalendarItemStatus.SentToClient].includes(x.status),
  );

  const disabled = hasSomeSentToClient;

  const handleEarlyDoneRecurringOrder = (isAccepted: boolean) => {
    if (disabled) {
      return;
    }

    if (isAccepted) {
      dispatch(
        earlyDoneRecurringPaymentOrderAsync.request({
          publicID: order.publicID,
          setIsOpen,
        }),
      );
    } else {
      setIsOpen(false);
    }
  };
  return (
    <>
      <OrderDetailIconButton
        onClick={() => setIsOpen(true)}
        disabled={disabled}
        title={
          disabled
            ? t(
                isReadOnly
                  ? Resources.Orders.EarlyDoneOrderRecurringPaymentDialog
                      .Tooltip.IsReadOnly
                  : Resources.Orders.EarlyDoneOrderRecurringPaymentDialog
                      .Tooltip.Disabled,
              )
            : t(
                Resources.Orders.EarlyDoneOrderRecurringPaymentDialog.Tooltip
                  .Enabled,
              )
        }
      >
        <CloseIcon />
      </OrderDetailIconButton>

      <BlConfirmDialog
        isLoadingConfirBtn={isLoadingSend}
        isOpen={isOpen}
        handleResult={handleEarlyDoneRecurringOrder}
        modalTitle={t(
          Resources.Orders.EarlyDoneOrderRecurringPaymentDialog.Title,
        )}
        modalContent={t(
          Resources.Orders.EarlyDoneOrderRecurringPaymentDialog.Text,
        )}
      />
    </>
  );
};
